import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import schoolOfGreatness from "../img/Logos/school-of-greatness-logo.png"
import di from "../img/Logos/DI_TRSM-logo.png"
import cc from "../img/Logos/canadian-congress-logo-footer.png"
import credit from "../img/Logos/credit_union.png"
import betty from "../img/Logos/bettys.png"
import dccba from "../img/Logos/dccba.png"
import efccc from "../img/Logos/efccc.png"
import srfc from "../img/Logos/solid_rock.png"
import tyndale from "../img/Logos/tyndale-university.png"
import afroGlobal from "../img/Logos/afro-global.png"
import acsdc from "../img/Logos/african-canadian-social-development-council.png"
import iwb from "../img/Logos/iwb.png"
import brilliantMind from "../img/Logos/brilliant-minds-group.png"
import oneVoice from "../img/Logos/one-voice-one-team.png"
import firstFriday from "../img/Logos/first-friday.png"
import georgeBrown from "../img/Logos/george-brown.png"
import rubicorn from "../img/Logos/rubicorn.png"
import bln from "../img/Logos/black-north-initiative.png"
import passerelle from "../img/Logos/la_passerelle.webp"

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container">
      <h2 className="header">Sponsors & Strategic Partners</h2>

        <div className="partner-logo row">
          <Link
            className="pl-table"
          >
            <img
              src= {schoolOfGreatness}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={di}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={cc}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={credit}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={tyndale}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={betty}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={dccba}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={efccc}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={srfc}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={afroGlobal}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={georgeBrown}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={firstFriday}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={oneVoice}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={brilliantMind}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={iwb}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={acsdc}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={rubicorn}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={bln}
              alt=""
              className="logo"
            />
          </Link>
          <Link
            className="pl-table"
          >
            <img
              src={passerelle}
              alt=""
              className="logo"
            />
          </Link>          
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-text">
              <div className="ft-social">
                <Link to="https://x.com/ccidwe?s=11&t=na9mLhcXv2zrQxOSmcZ_JQ" target="_blank" rel="noreferrer">
                  <i className="fa fa-twitter" />
                </Link>
                <Link to="https://www.linkedin.com/company/canadiancongressondiversity/" target="_blank" rel="noreferrer">
                  <i className="fa fa-linkedin" />
                </Link>
                <Link to="https://www.linkedin.com/company/canadiancongressondiversity/" target="_blank" rel="noreferrer">
                  <i className="fa fa-instagram" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
