import React from "react";
import AwardeesCard from "./AwardeesCard";
import "./AwardeesCard.css";

import ardavanEizadirad from "../img/Past_Awardees/ardavan-eizadirad.jpg";
import bernadetteClement from "../img/Past_Awardees/bernadette-clement.jpg";
import courtneyBetty from "../img/Past_Awardees/courtney-betty.jpg";
import donOliver from "../img/Past_Awardees/don-oliver.jpg";

import maryJaneMcCallum from "../img/Past_Awardees/mary-jane-mccallum.jpg";
import patriciaMorgan from "../img/Past_Awardees/patricia-morgan.jpg";
import peterMorgan from "../img/Past_Awardees/peter-morgan.jpg";
import svetlanaRatnikova from "../img/Past_Awardees/svetlana-ratnikova.jpeg";
import paulineChristian from "../img/Past_Awardees/pauline-christian.jpg";
import robertoHausman from "../img/GuestSpeakers/roberto-hausman.jpeg";
import jeanAugustine from "../img/GuestSpeakers/dr-jean-augustine.jpg";
import wendyCukier from "../img/GuestSpeakers/dr-wendy-cukier.jpg";

const awardees = [

  {
    image: jeanAugustine,
    name: "Hon. Jean Augustine",
    linkedin: "https://www.linkedin.com",
    title: "First Black Woman in the Parliament, Parliamentary Secretary to the Prime Minister; Minister for Multiculturalism and the Status of Women; Chair of the Foreign Affairs Committee; and Deputy Speaker, Founder of Jean Augustine Centre for Girls",
    award: "Lifetime Achievement Award",
  },
  {
    image: donOliver,
    name: "Senator Don Oliver",
    linkedin: "https://www.linkedin.com",
    title: "First Black Male in the Senate, Lawyer, Author, Social Justice Advocate",
    award: "Social Justice Icon Award",
  },
  {
    image: bernadetteClement,
    name: "Senator Bernadette Clement",
    linkedin: "https://www.linkedin.com",
    title: "Lawyer & First Female Mayor of Cornwall & Senator at the Senate of Canada",
    award: "Inclusive Politics & Government Award",
  },
  {
    image: maryJaneMcCallum,
    name: "Senator Mary Jane McCallum",
    linkedin: "https://www.linkedin.com",
    title: "Chancellor Brandon University, Manitoba & Senator of Canada",
    award: "National Unity & Harmony Award",
  },
  {
    image: wendyCukier,
    name: "Dr. Wendy Cukier",
    linkedin: "https://www.linkedin.com",
    title: "Founder of Toronto Metropolitan University’s Diversity Institute & The Academic director of the Women Entrepreneurship Knowledge Hub & Research Lead, Future Skills Centre",
    award: "Allyship & Diversity Leadership Award",
  },
  {
    image: courtneyBetty,
    name: "Courtney Betty",
    linkedin: "https://www.linkedin.com",
    title: "CEO, Betty’s Law Office, Lawyer, Consultant, Public Speaker, Diversity Strategist",
    award: "Law & Equitable Justice Award",
  },
  {
    image: paulineChristian,
    name: "Pauline Christian",
    linkedin: "https://www.linkedin.com",
    title: "Former President of the Black Business and Professional Association (BBPA) Founder/President/CEO of Best Lifestyle residence and Community Activist",
    award: "Black Excellence Entrepreneurship Award",
  },
  {
    image: peterMorgan,
    name: "Dr. Peter Morgan",
    linkedin: "https://www.linkedin.com",
    title: "Intrenational Speaker & Adjunct Lecturer at the Caribbean Nazarene College in Santa Cruz, Trinidad, and at the Graduate School of Theology in Kingston",
    award: "Global Stateman Award",
  },
  {
    image: patriciaMorgan,
    name: "Dr. Patricia Morgan",
    linkedin: "https://www.linkedin.com",
    title: "Psychologist and Consultant in Psychoeducational Evaluations, Nd Board Chairman of the University of FT Lauderdale & Best Selling Author",
    award: "Transformational Education Award",
  },
  {
    image: robertoHausman,
    name: "Roberto Hausman",
    linkedin: "https://www.linkedin.com",
    title: "Founder of Needy Children Assistance, Canadian Law Enforcement Training College & Diversity Leaders Alliance",
    award: "National Humanitarian Award",
  },
  {
    image: ardavanEizadirad,
    name: "Dr. Ardavan Eizadirad",
    linkedin: "https://www.linkedin.com",
    title: "Assistant Professor, Wilfred Laurier University & Director, Youth Association For Academics, Athletics, and Character Education (YAAACE)",
    award: "Youth & Community Development Award",
  },
  {
    image: svetlanaRatnikova,
    name: "Svetlana Ratnikova",
    linkedin: "https://www.linkedin.com",
    title: "Founder & CEO of Immigrant Women in Business (IWB), Co-Founder of RTG Group Inc., Social Entrepreneur, Mento, and Impact Investor",
    award: "Gender Equity & Women Empowerment Award",
  },
];

const AwardeesGrid2023 = () => {
  return (
    <section>
      <div className="header" id="awardees2023">
        <h1 className="title">Our Honourable <br />Awardees (2023)</h1>
        <p className="subheader">
          Celebrate the achievements of past award winners who have made a lasting impact
        </p>
      </div>
      <div className="awardees-grid container">
        {awardees.map((awardee, index) => (
          <AwardeesCard
            key={index}
            image={awardee.image}
            name={awardee.name}
            title={awardee.title}
            linkedin={awardee.linkedin}
            award={awardee.award}
            className="container"
          />
        ))}
      </div>
    </section>
  );
};

export default AwardeesGrid2023;
