import React, { Component } from "react";
import "./Pricing.css";
import pricingData from "../assets/data/data.json";
import background from "../img/cn-tower-background.jpg";

import rowanBarrett from "../img/Awardees/rowan-barrett.jpg";
import alexIhama from "../img/GuestSpeakers/nosakhare-alex-ihama.jpg";
import paulineChristine  from "../img/GuestSpeakers/pauline-christian.jpg";
import bdc from "../img/Logos/bdc.png";
import winstonlarose from "../img/Awardees/winston-larose.jpg"
import gregFergus from "../img/Awardees/greg-fergus.jpg";
import kamalajeangopie from "../img/Awardees/kamala-jean-gopie.jpg"
import wandaThomasBernard from "../img/Awardees/wanda-thomas-bernard.jpeg";
import senatorMurraySinclair from "../img/Awardees/senator-murray-sinclair.jpeg";
import michaëlleJean from "../img/Awardees/michaëlle-jean.jpg"
import drGervanFearon from "../img/Awardees/dr-gervan-fearon.jpeg";
import harrietThornhill from "../img/Awardees/harriet-thornhill.jpeg";
import drJeanAugustine from "../img/GuestSpeakers/dr-jean-augustine.jpg";
import ontario_trillium from "../img/Logos/otf.png"
import diversity_institute from "../img/Logos/di.jpg"
import rbc from "../img/Logos/rbc.png"
import solidRock from "../img/Logos/solid_rock.png"
import schoolofGreatness from "../img/Logos/school-of-greatness-logo.png"
import canadianCongress from "../img/Logos/canadian-congress-logo-1.png"
import donaldMcLeod from "../img/Awardees/justice-donald-mcleod.jpg";
import dianaDSouza from "../img/Awardees/diana-dsouza.jpg";
import yaaace from "../img/Logos/yaaace.jpg"
import laPasserelle from "../img/Logos/la_passerelle.webp"
import nicholasMarcusThompson from "../img/Awardees/nicholas-thompson.jpeg";
import patriciaBebiaMawa from "../img/Awardees/patricia-bebia-mawa.jpg";
import acsdc from "../img/Logos/african-canadian-social-development-council.png";
import oneVoice from "../img/Logos/one-voice-one-team.png";
import blackClassAction from "../img/Logos/black-class-action.png";
import soldOut from "../img/Logos/sold-out.png";
// import avaliable from "../img/Logos/avaliable.jpg"

const specialTables = [
  { name: "Hon. Dr. Jean Augustine Table", image: drJeanAugustine, full:"Full"},
  { name: "Senator Murray Sinclair Table", image: senatorMurraySinclair , full:"Open"},
  { name: "Mrs. Harriet Thornhill Table", image: harrietThornhill , full:"Full"},
  { name: "Mr. Rowan Barrett Table", image: rowanBarrett , full:"Open"},
  { name: "Senator Wanda Thomas Bernard Table", image: wandaThomasBernard , full:"Open"},
  { name: "Dr. Gervan Fearon Table", image: drGervanFearon , full:"Open"},
  { name: "Dr. Winston LaRose Table", image: winstonlarose , full:"Open"},
  { name: "Ms. Kamala Jean Gopie Table", image: kamalajeangopie , full:"Open"},
  { name: "Hon. Michaëlle Jean Table", image: michaëlleJean , full:"Open"},
  { name: "Hon. Greg Fergus Table", image: gregFergus, full:"Open" },
  { name: "Nicholas Marcus Thompson Table", image: nicholasMarcusThompson, full:"Full" },
  { name: "Nosakhare Alex Ihama Table", image: alexIhama, full:"Full" },
  { name: "Pauline Christine Table", image: paulineChristine, full:"Full" },
  { name: "Justice Donald McLeod Table", image: donaldMcLeod, full:"Open" },
  { name: "Diana Alli D'Souza Table", image: dianaDSouza, full:"Open" },
  { name: "Patricia Bebia Mawa Table", image: patriciaBebiaMawa, full:"Full" },
  { name: "Business Development Bank (BDC) Table", image: bdc, full:"Full" },
  { name: "Royal Bank RBC Table", image: rbc, full:"Full" },
  { name: "Diversity Institute Table", image: diversity_institute, full:"Full" },
  { name: "Ontario Trillium Foundation Table", image: ontario_trillium, full:"Full" },
  { name: "Solid Rock Table", image: solidRock, full:"Open" },
  { name: "School of Greatness Table", image: schoolofGreatness, full:"Open" },
  { name: "Canadian Congress Table", image: canadianCongress, full:"Open" },
  { name: "Youth Association for Academics, Athletics, and Character Education Table", image: yaaace, full:"Full" },
  { name: "La Passerelle Table", image: laPasserelle, full:"Full" },
  { name: "Black Class Action Secretariat Table", image: blackClassAction, full:"Full" },
  { name: "One Voice One Team Table", image: oneVoice, full:"Open" },
  { name: "African Canadian Social Development Council Table", image: acsdc, full:"Open" },
];

class Pricing extends Component {
  render() {
    return (
      <div
        className="pricing-container x-pad"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="pricing-body">
          <h1>Invest in yourself today</h1>
          <section className="special-tables-section">
              <h2>Who would you like to network with?</h2>
              <div className="special-tables-message">Seize the opportunity to sit at one of these exclusive tables and immerse yourself in a truly marvelous event!</div>
              <div className="table-list">
                {specialTables.map((table, index) => (
                  <div className="table-item" key={index}>
                    <div className="table-image">
                      <img src={table.image} alt={table.name}/>
                    </div>
                    <a className="table-link" href="https://www.eventbrite.ca/e/canada-leadership-summit-nation-builders-award-gala-2025-tickets-1043006178557" target="_blank" rel="noreferrer">
                      <div className="table-info">
                        <span className="table-number">{index + 1}.</span>
                        <span className="table-name">{table.name}</span>
                      </div>
                    </a>
                    <span className=""   
                    style={{
                        fontWeight: 'bold',
                        color: table.full === 'Full' ? 'red' : 'green'
                    }}>{table.full}</span>
                  </div>
                ))}
            </div>
            <div className="disclaimer">*Please note that seating at the listed tables is exclusive to through Platinum and Gold Tickets</div>
          </section>

          <div className="pricing-card-container">
            {pricingData.pricingPlans.map((plan, index) => (
              <div className="pricing-card" key={index}>
                <p className="pricing-header">{plan.pricingHeader}</p>
                <div className="price-container">
                  <p className="price">
                    <span>$</span>
                    {plan.price}
                  </p>
                </div>
                <ul>
                  {plan.perk1 && (
                    <li className="fa fa-check-circle">
                      <p className="price-detail">{plan.perk1}</p>
                    </li>
                  )}
                  {plan.perk2 && (
                    <li className="fa fa-check-circle">
                      <p className="price-detail">{plan.perk2}</p>
                    </li>
                  )}
                  {plan.perk3 && (
                    <li className="fa fa-check-circle">
                      <p className="price-detail">{plan.perk3}</p>
                    </li>
                  )}
                  {plan.perk4 && (
                    <li className="fa fa-check-circle">
                      <p className="price-detail">{plan.perk4}</p>
                    </li>
                  )}
                  {plan.perk5 && (
                    <li className="fa fa-check-circle">
                      <p className="price-detail">{plan.perk5}</p>
                    </li>
                  )}
                </ul>
                {plan.availability === "No" ? (
                  <img src={soldOut} alt="Sold Out" />
                ) : (
                  <button onClick={() => window.location.href = "https://www.eventbrite.ca/e/canada-leadership-summit-nation-builders-award-gala-2025-tickets-1043006178557"}>
                    Get your Tickets Today!
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Pricing;