import React, { useState, useEffect, useCallback } from "react";
import "./Countdown.css"; 

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }, [targetDate]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, calculateTimeLeft]);

  return (
    <section className="counter-section bg-gradient">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="counter-text">
              <span>Oct 3 - 5, 2025</span>
              <h3>
                Count Every Second <br /> Until the Moment
              </h3>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="cd-timer" id="countdown">
              <div className="cd-item">
                <span>{timeLeft.days || "0"}</span>
                <p>Days</p>
              </div>
              <div className="cd-item">
                <span>{timeLeft.hours || "0"}</span>
                <p>Hours</p>
              </div>
              <div className="cd-item">
                <span>{timeLeft.minutes || "0"}</span>
                <p>Minutes</p>
              </div>
              <div className="cd-item">
                <span>{timeLeft.seconds || "0"}</span>
                <p>Seconds</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Countdown;
