import React from "react";
import Countdown from "../components/Countdown";
import Pricing from "../components/Pricing";
import ContactInfo from "../components/ContactInfo";
// import ScheduleSection from "../components/ScheduleSection";
// import Divider from "../components/Divider";
import AwardeesGrid from "../components/AwardeesGrid";
import CommunityAward from "../components/CommunityAward";
import GuestSpeaker from "../components/GuestSpeaker";
import NewsletterSignup from "../components/NewsletterSignup";
import "./Home.css";
import hero from "../img/Home/hero-background.jpg";
import welcomeImage from "../img/Home/alex_ihama.png"

// import flyer1 from "../img/flyer-1.jpg";
// import flyer2 from "../img/flyer-2.jpg";

import schedule1 from "../img/schedule-1.jpg";
import schedule2 from "../img/schedule-2.jpg"


const Home = () => {
  const targetDate = "2025-10-03T00:00:00";
  return (
    <>
      <section className="home-about-section x-pad">
        <h2 className="welcome-title">Welcome Message By Executive Director <b>Nosakhare Alex Ihama</b> <h5 className="book-title">Global Strategist, Executive Coach, Professional Speaker & Bestselling Author of "The Mystique of Leadership"</h5></h2>
        <div className="welcome-container">
          <div className="video-container">
            <iframe className="hero-video" src="https://www.youtube.com/embed/1_79J_zxb5s?si=d1FNdZh1uXZzOd7P&amp;controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="text-container">
            <p className="text-align">
                We are sincerely grateful for your interest in the Canada Leadership Summit & Nation
                Builders Award Gala, a program that represents the culmination of our commitment to
                transformational leadership and social justice. Whether you are considering attending,
                sponsoring, or simply exploring our work, your involvement means the world to us and for
                that, we say thank you.
              <br/>
              <br/>
              <div className="image-container bottom-image">
                <img src={welcomeImage} alt="Welcome Message"/>
              </div>
                For over two decades, our organization has been at the forefront of hosting some of the
                largest events focused on driving change through leadership. In addition, I travel across
                many cities, countries and continents each year, inspiring thousands to greater heights in
                life, business, leadership and social justice advocacy. But once a year, we gather leaders from
                across the globe to convene in person for three days of unparalleled networking, high-level
                training programs, and collective advocacy.
                The highlight of our gathering is the Nation Builders Award Gala, where we celebrate
                extraordinary individuals who have demonstrated brilliance in the areas which we believe
                are critical to the transformation of nations. These awardees embody the values we strive to
                uphold and serve as living legends in their respective fields. This event enables us to tap into
                their vast reservoir of wisdom.
              <br/>
              <br/>
                Therefore, we warmly invite you to register for this
                year’s Summit, promote our mission within your
                network, and consider becoming a sponsor or
                strategic partner to help sustain and grow this vital
                endeavour. Your support and participation not only
                enrich the event but also contribute to a global
                movement toward an equitable world.
            </p>
          </div>
        </div>
        <div className="feedback">
        <a href="https://www.surveymonkey.com/r/canadaleadershipsummitfeedback" target="_blank" rel="noreferrer" className="primary-btn learn-more">
          Give your feedback on the 2024 Canada Leadership Summit & Gala!
        </a>
      </div>
      </section>
      
      <div className="center-mag">
        <h3 className="mag-title">Take a look at the Nation Builders magazines <br/> for insightful stories on leadership and development!</h3>
          <iframe src="https://drive.google.com/file/d/1fEgI0m3_TpjW_1M-2HvtlzFy2v3lwSEY/preview" width="80%" height="800px" title="PDF Document Preview"></iframe>
        </div>
      
      <Countdown targetDate={targetDate} />
      <section
        className="hero-section set-bg hero-bg"
        style={{
          backgroundImage: `url(${hero})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7 banner-center">
              <div className="hero-text">
                <span>Oct 3 - 5th 2025 @ Hilton Toronto Airport Hotel & Suite</span>
                <h2>
                Transforming Leaders into Nation Builders
                </h2>
                <a href="https://www.eventbrite.ca/e/canada-leadership-summit-nation-builders-award-gala-2025-tickets-1043006178557" target="_blank" rel="noreferrer" className="primary-btn">
                  Buy Ticket Now!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-about-section x-pad" id="about">
        <div className="container ptop">
          <div className="row center-img">
            <div className="col-lg-6 col-md-12 text-center">
              {/* <img src={flyer1} alt="flyer 1" className="img-fluid flyer" /> */}
              <h3 className="header-padding">About Canada Leadership Summit</h3>
              <p className="text-align">
                The Canada Leadership Summit is a premier global event that gathers leaders from diverse backgrounds, all united by a shared dedication to
                transformational leadership and tackling today’s most urgent issues through networking, advocacy, and steadfast commitment.
                This dynamic and empowering two-day event brings together thought
                leaders, innovators, social justice advocates, and changemakers from around the world for profound dialogue, collaboration, and action.
                Central to the Summit’s mission is the goal of inspiring participants to rise above personal, professional, and corporate challenges. 
                We focus on moving beyond the comfort of influence and affluence to make a meaningful impact on national policies and global affairs.
                <br />
                <br />
                Our aim is to encourage everyone to become social justice advocates and to transform leaders into nation-builders who are committed 
                to the ongoing evolution of Canada and their own countries. Featuring over 40 distinguished speakers, each session is designed to
                provoke thought, foster crucial conversations, and equip delegates with the tools and insights needed for greater impact, influence, and success.
                Attendees will leave the Summit not only with a renewed sense of purpose but also with the confidence, competence, and capability to advocate
                effectively and drive the change they wish to see in the world.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 text-center">
              {/* <img src={flyer2} alt="flyer 2" className="img-fluid flyer" /> */}
              <h3 className="header-padding">About Nation Builders Award</h3>
              <p className="text-align">
                The Nation Builder Awards Gala is one of Canada’s most prestigious events dedicated to honouring 12 to 14 extraordinary individuals who 
                have achieved legendary status as champions of social justice. These men and women have spent decades advocating for equality, fairness, 
                and transformative change through their platforms and professions, leaving an indelible mark on their communities and the world at large.
                Our awardees are carefully selected for their brilliance in areas we consider essential to the transformation of a nation. Each recipient 
                has a resume that is undisputed both nationally and internationally, showcasing a lifetime of dedication to transformational leadership and 
                advancing social justice. What sets this award program apart is our commitment to recognizing those who continue to advocate for these 
                critical issues, earning the unanimous vote of our selection team.
                <br />
                <br />
                During the Gala, these nation heroes are given a few minutes to inspire the audience with their stories of greatness, offering insights 
                and wisdom drawn from their remarkable journeys. Their words not only celebrate their past achievements but also encourage future leaders 
                to continue the fight for justice and equality. We invite you to register for the Nation Builder Awards Gala, secure a table for your 
                organization, and promote this event within your network. Additionally, we welcome you to consider becoming a sponsor or strategic partner
                to help sustain this elaborate and impactful endeavour. Your support will ensure that we continue to recognize and uplift those who lead 
                the charge in creating a better world for all.
              </p>
            </div>
          </div>
          <span className="center-div slogan">Canada Leadership Summit and Nation Builders Award is more than just an event—it's a catalyst for leadership, transformation & global impact!</span>
          <div className="center-div">
            <div className="schedule-container">
              <img className="schedule-image" src={schedule1} alt="schedule"/>
              <img className="schedule-image" src={schedule2} alt="schedule"/>
            </div>
          </div>
          <div className="center-div">
            <a href="assets/doc/About-Canada-Leadership-Summit-and-Nation-Builders-Award.pdf" target="_blank" rel="noreferrer" className="primary-btn learn-more">
              Download the Canada Leadership Summit and Nation Builders Award Brochure!
            </a>
          </div>
        </div>
      </section>
      <GuestSpeaker />
      <Pricing />
      {/* <ScheduleSection/> */}
      <div className="center-div">
        <a href="https://www.eventbrite.ca/e/canada-leadership-summit-nation-builders-award-gala-2025-tickets-1043006178557" target="_blank" rel="noreferrer" className="primary-btn">Get your Tickets Now!</a>
      </div>
      <AwardeesGrid/>
      <div className="center-div">
        <a href="https://forms.gle/pJzKzVMq6svzsej4A" target="_blank" rel="noreferrer" className="primary-btn learn-more">
          Nominate your 2025 candidate now!
        </a>
      </div>
      <CommunityAward/>
      <NewsletterSignup />
      {/* <Divider /> */}
      <ContactInfo />
    </>
  );
};

export default Home;
