import React, { useState, useEffect } from "react";
import "./NewsletterSignup.css";
import newsletter from "../img/newsletter.webp";

const NewsletterSignup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  // Load Kajabi form script when component mounts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.canadiancongressondiversity.ca/forms/2148476421/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up script when component unmounts
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email) {
      setStatusMessage("Please enter a valid name and email.");
      return;
    }

    // Since we're using Kajabi's form, you might not need this
    setTimeout(() => {
      setStatusMessage("Thank you for subscribing!");
    }, 1000);

    setName("");
    setEmail("");
  };

  return (
    <div className="newsletter-signup">
      <img className="newsletter-img" src={newsletter} alt="Newsletter" />
      <div className="newsletter-signup-container">
        <h2>Gain FREE Access to a Leadership & Diversity Course</h2>
        <h5>Join thousands of subscribers who have been empowered through our transformational content!</h5>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First and Last Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Sign Up</button>
        </form>
        <p className="statusMessage">{statusMessage}</p>
      </div>
    </div>
  );
};

export default NewsletterSignup;
