import React from 'react';
import './GuestSpeaker.css';

import nosakhareAlexIhama from "../img/GuestSpeakers/nosakhare-alex-ihama.jpg";
import austinBrown from "../img/Past_Speakers/austin-brown.jpg";
import blessingAjayi from "../img/Past_Speakers/blessing-ajayi.jpg";
import canuteBlake from "../img/Past_Speakers/canute-blake.jpg";
import carltonFacey from "../img/Past_Speakers/carlton-facey.png";
import charmainRemy from "../img/Past_Speakers/charmain-remy.jpg";
import henryLuyombya from "../img/Past_Speakers/henry-luyombya.jpg";
import joyceWestJohnson from "../img/Past_Speakers/joyce-west-johnson.jpg";
import kingsleyOsayande from "../img/Past_Speakers/kingsley-osayande.jpg";
import lennoxWalker from "../img/Past_Speakers/lennox-walker.jpg";
import lydiaFrancis from "../img/Past_Speakers/lydia-francis.jpg";
import merleCann from "../img/Past_Speakers/merle-cann.jpg";
import moyFung from "../img/Past_Speakers/moy-fung.jpg";
import patFrancis from "../img/Past_Speakers/pat-francis.jpg";
import ransfordJones from "../img/Past_Speakers/ransford-jones.png";
import rossCadastre from "../img/Past_Speakers/ross-cadastre.jpg";
import samTita from "../img/Past_Speakers/sam-tita.jpg";
import nicholasMarcusThompson from "../img/Awardees/nicholas-thompson.jpeg";
import alethiaOharaStephenson from "../img/GuestSpeakers/alethia-ohara-stephenson.jpg";




const speakers = [
  { id: 17, name: 'Nosakhare Alex Ihama', image: nosakhareAlexIhama },
  { id: 1, name: 'Austin Brown', image: austinBrown },
  { id: 2, name: 'Blessing Ajayi', image: blessingAjayi },
  { id: 3, name: 'Canute Blake', image: canuteBlake },
  { id: 4, name: 'Carlton Facey', image: carltonFacey },
  { id: 5, name: 'Charmain Remy', image: charmainRemy },
  { id: 6, name: 'Henry Luyombya', image: henryLuyombya },
  { id: 7, name: 'Joyce West Johnson', image: joyceWestJohnson },
  { id: 8, name: 'Kingsley Osayande', image: kingsleyOsayande },
  { id: 9, name: 'Lennox Walker', image: lennoxWalker },
  { id: 10, name: 'Lydia Francis', image: lydiaFrancis },
  { id: 11, name: 'Merle Cann', image: merleCann },
  { id: 12, name: 'Moy Fung', image: moyFung },
  { id: 13, name: 'Pat Francis', image: patFrancis },
  { id: 14, name: 'Ransford Jones', image: ransfordJones },
  { id: 15, name: 'Ross Cadastre', image: rossCadastre },
  { id: 16, name: 'Sam Tita', image: samTita },
  { id: 18, name: 'Nicholas Marcus Thompson', image: nicholasMarcusThompson },
  { id: 19, name: 'Alethia Ohara Stephenson', image:  alethiaOharaStephenson },

];

const GuestSpeaker2023 = () => {
  return (
    <div className="header" id="speakers2023">
        <h1 className="title">Speakers and Panelists (2023)</h1>
        <p className="subheader">
        Honour the insights and expertise of our past guest speakers and panelist who are shaping the future.
        </p>
        <div className="guest-speaker-grid">
        {speakers.map((speaker) => (
          <div key={speaker.id} className="guest-speaker-item">
            <div className="guest-speaker-image-container">
              <img src={speaker.image} alt={speaker.name} className="guest-speaker-image" />
            </div>
            <p className="guest-speaker-name">{speaker.name}</p>
          </div>
        ))}
        </div>
    </div>
  );
};

export default GuestSpeaker2023;
