import React from 'react';
import './GuestSpeaker.css';

// import adejisolaatiba from "../img/Community_Developer/adejisola-atiba.jpg";
import alethiaOharaStephenson from "../img/GuestSpeakers/alethia-ohara-stephenson.jpg";
import devonjones from "../img/Community_Developer/devon-jones.jpg";
import dineshsharma from "../img/Community_Developer/dinesh-sharma.jpg";
import evangelinechima from "../img/Community_Developer/evangeline-chima.jpg";
import henryLuyombya from "../img/GuestSpeakers/henry-luyombya.jpg";
import kabuneneasante from "../img/Community_Developer/kabu-nene-asante.jpg";
import leonietchatat from "../img/Community_Developer/leonie-tchatat.jpg";
import moyFung from "../img/GuestSpeakers/moy-fung.jpg";
import ngozigertrudeugoh from "../img/Community_Developer/ngozi-gertrude-ugoh.jpg";
import orlandobowen from "../img/Community_Developer/orlando-bowen.jpg";
import ositanwajiufor from "../img/Community_Developer/osita-nwajiufor.jpg";
import ruthnyaamine from "../img/Community_Developer/ruth-nyaamine.jpg";
import svetlanaRatnikova from "../img/Past_Awardees/svetlana-ratnikova.jpeg";
import warrensalmon from "../img/Community_Developer/warren-salmon.jpg";

const awardwinners = [
  // { id: 1, name: 'Adejisola Atiba', image: adejisolaatiba, organization: "African Caribbean Council of Halton (ACCH)"},
  { id: 15, name: 'Warren Salmon', image: warrensalmon, organization: "First Fridays Toronto" },
  { id: 3, name: 'Devon Jones', image: devonjones, organization: "Youth Association for Academics, Athletics & Character Education (YAAACE)"},
  { id: 4, name: 'Dinesh Sharma', image: dineshsharma, organization: "Brilliant Minds Group"},
  { id: 2, name: 'Alethia O’Hara-Stephenson', image: alethiaOharaStephenson, organization: "Dufferin County Canadian Black Association"},
  { id: 5, name: 'Evangeline Chima', image: evangelinechima, organization: "Black Mentorship Inc"},
  { id: 6, name: 'Henry Kudda Luyombya', image: henryLuyombya, organization: "New African Canadians (NAC)" },
  { id: 7, name: 'Kabu Nene Asante', image: kabuneneasante, organization: "African Canadian Social Development Council" },
  { id: 8, name: 'Leonie Tchatat', image: leonietchatat, organization: "La Passerelle-I.D.E" },
  { id: 9, name: 'Moy Fung', image: moyFung, organization: "Solid Rock Family Services" },
  { id: 10, name: 'Ngozi Gertrude Ugoh', image: ngozigertrudeugoh, organization: "Nigerian Canadian Newspaper" },
  { id: 11, name: 'Orlando Bowen', image: orlandobowen, organization: "One Voice, One Team" },
  { id: 12, name: 'Osita Nwajiufor', image: ositanwajiufor, organization: "Black Tech Hub" },
  { id: 13, name: 'Ruth Nyaamine', image: ruthnyaamine, organization: "Ladder 2 Rise" },
  { id: 14, name: 'Svetlana Ratnikova', image: svetlanaRatnikova, organization: "Immigrants Women in Business Organization (IWB)" },
];


const GuestSpeaker = () => {
  return (
    <div className="header">
        <h2 className="title">Community Developer Award</h2>
        <p className="subheader">
          Celebrate the contributions and expertise of our community leaders and award recipients who are shaping the future
        </p>
        <div className="guest-speaker-grid">
        {awardwinners.map((speaker) => (
          <div key={speaker.id} className="guest-speaker-item">
            <div className="guest-speaker-image-container">
              <img src={speaker.image} alt={speaker.name} className="guest-speaker-image" />
            </div>
            <p className="guest-speaker-name">{speaker.name}</p>
            <p className="organization">{speaker.organization}</p>
          </div>
        ))}
        </div>
    </div>
  );
};

export default GuestSpeaker;
