import AwardeesGrid from "../components/AwardeesGrid";
import AwardeesGrid2023 from "../components/AwardeesGrid2023";



const Awardees = () => {
    return (
      <>
        <div className="center-div">
          <a href="https://forms.gle/pJzKzVMq6svzsej4A" target="_blank" rel="noreferrer" className="primary-btn learn-more">
            Nominate your 2025 candidate now!
          </a>
        </div>
        <AwardeesGrid/>
        <AwardeesGrid2023/>
      </>
    );
  };
  
  export default Awardees;